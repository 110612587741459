<!-- <template>
  <div :class="`right-sidebar-mini ${miniClass}`">
    <div class="right-sidebar-panel p-0">
      <div class="card shadow">
        <div class="card-body p-0">
          <div class="media-height p-3" data-scrollbar="init" v-infinite-scroll="getRows"
            :infinite-scroll-disabled="commomData.noResult">
            <div v-for="(item, index) in list" :key="index" class="d-flex align-items-center mb-4">
              <div :class="`iq-profile-avatar status-${item.status}`">
                <img class="rounded-circle avatar-50" :src="item.avatar" alt="" />
              </div>
              <div class="ms-3">
                <h6 class="mb-0">{{ item.user_account }}</h6>
                <p v-if="item.is_read" class="mb-0">{{ item.content }}</p>
                <h6 v-else class="mb-0">{{ item.content }}</h6>
              </div>
            </div>
          </div>
          <div class="right-sidebar-toggle bg-primary text-white mt-3" @click="toggleMini">
            <i class="ri-arrow-left-line side-left-icon"></i>
            <i class="ri-arrow-right-line side-right-icon"><span class="ms-3 d-inline-block">Close Menu</span></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { chatList } from '@/api/chat'
import { commomData } from '@/utils/commonVmodel'

export default {
  name: 'DefaultRightSidebar',
  setup() {},
  data() {
    return {
      miniClass: '',
      rightSideBarMini: false,
      list: [],
      primaryKey: 'created_at',
      commomData: commomData()
    }
  },
  mounted() {
    this.getRows()
  },
  methods: {
    toggleMini() {
      this.rightSideBarMini = !this.rightSideBarMini
      this.checkRightSideBar()
    },
    checkRightSideBar() {
      const body = document.querySelector('body')
      if (this.rightSideBarMini) {
        this.miniClass = 'right-sidebar'
        body.classList.add('right-sidebar-close')
      } else {
        this.miniClass = ''
        body.classList.remove('right-sidebar-close')
      }
    },
    async getRows() {
      if (this.commomData.noResult) {
        return false
      }
      this.commomData.listLoader = true
      this.commomData.condition.order_by = this.primaryKey
      this.commomData.condition.post_type = 1
      this.commomData.condition.user_account = this.$route.params.user_account
      await chatList(this.commomData.condition)
        .then((res) => {
          if (this.commomData.condition.page === 1) {
            this.list = res.data
            this.commomData.condition.page = 2
            this.commomData.listLoader = false
            if (res.total === 0) {
              this.commomData.noResult = true
            }
          } else if (res.total > this.list.length) {
            this.list.push(...res.data)
            this.commomData.condition.page = this.commomData.condition.page + 1
            this.commomData.listLoader = false
          } else if (res.total === this.list.length) {
            this.commomData.noResult = true
            this.commomData.listLoader = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
}
</script> -->
<template>
  <div></div>
</template>
